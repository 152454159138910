import validate from "/Users/KA/Primax/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/Users/KA/Primax/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/Users/KA/Primax/middleware/auth.js"),
  guest: () => import("/Users/KA/Primax/middleware/guest.js"),
  verification: () => import("/Users/KA/Primax/middleware/verification.js"),
  verified: () => import("/Users/KA/Primax/middleware/verified.js")
}