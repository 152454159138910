

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"ssr":true}
  
  
  
  
  return options
}

