import revive_payload_client_4sVQNw7RlN from "/Users/KA/Primax/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "/Users/KA/Primax/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/Users/KA/Primax/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/KA/Primax/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/KA/Primax/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/KA/Primax/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/KA/Primax/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/KA/Primax/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/KA/Primax/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/KA/Primax/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/KA/Primax/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_wMKVLl9fyU from "/Users/KA/Primax/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import plugin_t2GMTTFnMT from "/Users/KA/Primax/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_tMGwffvjBc from "/Users/KA/Primax/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import pwa_icons_Y6agwlnXZq from "/Users/KA/Primax/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/Users/KA/Primax/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_AUP22rrBAc from "/Users/KA/Primax/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import firebase_Tc1lVUbp7M from "/Users/KA/Primax/plugins/firebase.js";
import firebase_auth_client_p16UxoRYvf from "/Users/KA/Primax/plugins/firebase-auth.client.js";
import vuetify_8NhHJigKc1 from "/Users/KA/Primax/plugins/vuetify.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/Users/KA/Primax/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_wMKVLl9fyU,
  plugin_t2GMTTFnMT,
  plugin_tMGwffvjBc,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  plugin_AUP22rrBAc,
  firebase_Tc1lVUbp7M,
  firebase_auth_client_p16UxoRYvf,
  vuetify_8NhHJigKc1,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]