import { default as admissionsxDEySXQTzvMeta } from "/Users/KA/Primax/pages/admissions.vue?macro=true";
import { default as alevelSxqqjWYfKQMeta } from "/Users/KA/Primax/pages/alevel.vue?macro=true";
import { default as blogsf7PmjrdYJPMeta } from "/Users/KA/Primax/pages/blogs.vue?macro=true";
import { default as subscription_45cancel5DYvKYkc88Meta } from "/Users/KA/Primax/pages/checkout/subscription-cancel.vue?macro=true";
import { default as subscription_45successfulId2Lkd6LsxMeta } from "/Users/KA/Primax/pages/checkout/subscription-successful.vue?macro=true";
import { default as companyRXGFY3SZB0Meta } from "/Users/KA/Primax/pages/company.vue?macro=true";
import { default as indexEmsrBfzf7tMeta } from "/Users/KA/Primax/pages/index.vue?macro=true";
import { default as login0QKmduQH0TMeta } from "/Users/KA/Primax/pages/login.vue?macro=true";
import { default as past_45papers_45selectorJr69AMOzRPMeta } from "/Users/KA/Primax/pages/past-papers-selector.vue?macro=true";
import { default as _91id_93PJS08PZHgyMeta } from "/Users/KA/Primax/pages/pastpapers/[id].vue?macro=true";
import { default as indexGGFldb3vjTMeta } from "/Users/KA/Primax/pages/payment/index.vue?macro=true";
import { default as privacy_45policyOFCm6Ba0dAMeta } from "/Users/KA/Primax/pages/privacy-policy.vue?macro=true";
import { default as updateQ6si06WBrNMeta } from "/Users/KA/Primax/pages/profile/update.vue?macro=true";
import { default as userga6R8g99q1Meta } from "/Users/KA/Primax/pages/profile/user.vue?macro=true";
import { default as sign_45upgqyZ1JiEJrMeta } from "/Users/KA/Primax/pages/sign-up.vue?macro=true";
import { default as subjectsxCrcqlkSMNMeta } from "/Users/KA/Primax/pages/subjects.vue?macro=true";
import { default as indexMnOX1k7rFLMeta } from "/Users/KA/Primax/pages/subscribe/index.vue?macro=true";
import { default as terms_45and_45conditions5fi8Pzt32yMeta } from "/Users/KA/Primax/pages/terms-and-conditions.vue?macro=true";
import { default as tutoringrYCS4fs08eMeta } from "/Users/KA/Primax/pages/tutoring.vue?macro=true";
import { default as verify_45emailXR0SCzipkyMeta } from "/Users/KA/Primax/pages/verify-email.vue?macro=true";
export default [
  {
    name: "admissions",
    path: "/admissions",
    component: () => import("/Users/KA/Primax/pages/admissions.vue")
  },
  {
    name: "alevel",
    path: "/alevel",
    component: () => import("/Users/KA/Primax/pages/alevel.vue")
  },
  {
    name: "blogs",
    path: "/blogs",
    component: () => import("/Users/KA/Primax/pages/blogs.vue")
  },
  {
    name: "checkout-subscription-cancel",
    path: "/checkout/subscription-cancel",
    component: () => import("/Users/KA/Primax/pages/checkout/subscription-cancel.vue")
  },
  {
    name: "checkout-subscription-successful",
    path: "/checkout/subscription-successful",
    component: () => import("/Users/KA/Primax/pages/checkout/subscription-successful.vue")
  },
  {
    name: "company",
    path: "/company",
    component: () => import("/Users/KA/Primax/pages/company.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/KA/Primax/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login0QKmduQH0TMeta || {},
    component: () => import("/Users/KA/Primax/pages/login.vue")
  },
  {
    name: "past-papers-selector",
    path: "/past-papers-selector",
    component: () => import("/Users/KA/Primax/pages/past-papers-selector.vue")
  },
  {
    name: "pastpapers-id",
    path: "/pastpapers/:id()",
    component: () => import("/Users/KA/Primax/pages/pastpapers/[id].vue")
  },
  {
    name: "payment",
    path: "/payment",
    meta: indexGGFldb3vjTMeta || {},
    component: () => import("/Users/KA/Primax/pages/payment/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/Users/KA/Primax/pages/privacy-policy.vue")
  },
  {
    name: "profile-update",
    path: "/profile/update",
    meta: updateQ6si06WBrNMeta || {},
    component: () => import("/Users/KA/Primax/pages/profile/update.vue")
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: userga6R8g99q1Meta || {},
    component: () => import("/Users/KA/Primax/pages/profile/user.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upgqyZ1JiEJrMeta || {},
    component: () => import("/Users/KA/Primax/pages/sign-up.vue")
  },
  {
    name: "subjects",
    path: "/subjects",
    component: () => import("/Users/KA/Primax/pages/subjects.vue")
  },
  {
    name: "subscribe",
    path: "/subscribe",
    meta: indexMnOX1k7rFLMeta || {},
    component: () => import("/Users/KA/Primax/pages/subscribe/index.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/Users/KA/Primax/pages/terms-and-conditions.vue")
  },
  {
    name: "tutoring",
    path: "/tutoring",
    component: () => import("/Users/KA/Primax/pages/tutoring.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: verify_45emailXR0SCzipkyMeta || {},
    component: () => import("/Users/KA/Primax/pages/verify-email.vue")
  }
]